

import { KeepAlive } from '@/utils/decorators'
import { ElForm } from 'element-ui/types/form'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'InventoryList'
})
@KeepAlive
export default class Inventory extends Vue {
  searchForm = {
    orderNumber: '', // 单据编号
    startDate: '', // 开始时间
    endDate: '', // 结束时间
    inventoryState: '' // 盘点状态1盘点中 2已结束
  }

  formData = {
    remarks: '', // 备注
    projectId: '', // 所属项目
    orderDate: '', // 工单日期
    inventoryName: '', // 盘点名称
    inventoryState: '', // 盘点状态1盘点中 2已结束
    inventoryTypeList: [], // 盘点类型名称,隔开
    inventoryLocation: '', // 盘点位置名称,隔开
    inventoryUserId: '' // 盘点员
  }

  rules = {
    inventoryName: [{ required: true, message: '请输入盘点名称', trigger: ['change', 'blur'] }],
    inventoryUserId: [{ required: true, message: '请选择盘点员', trigger: ['change', 'blur'] }],
    projectId: [{ required: true, message: '请选择盘点项目', trigger: ['change', 'blur'] }],
    inventoryTypeList: [{ required: true, message: '请选择盘点资源分类', trigger: ['change', 'blur'] }],
    inventoryLocation: [{ required: true, message: '请选择盘点存放位置', trigger: ['change', 'blur'] }]
  }

  dialogVisible = false

  dataArr = []

  page = 1
  size = 10
  total = 0
  items = []

  assetTypeTree = []
  assetLocationTree = []
  userList = []

  get projectList () {
    return this.$store.state.projectList
  }

  get userInfo () {
    return JSON.parse(sessionStorage.getItem('userInfo') || '{}')
  }

  created () {
    this.loadData()
    this.loadAssetTypeTree()
    this.loadAssetLocationTree()
    this.loadUserList()
  }

  loadData () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetInventoryByPage, {
      ...this.searchForm,
      page: this.page,
      size: this.size
    }).then(res => {
      this.items = res.list
      this.total = res.total
    })
  }

  loadAssetTypeTree () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetTypeByList).then(res => {
      this.assetTypeTree = res
    })
  }

  loadAssetLocationTree () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetLocationByList).then(res => {
      this.assetLocationTree = res
    })
  }

  loadUserList () {
    this.$axios.get(this.$apis.scenarios.selectUserListByList).then(res => {
      this.userList = res.list || []
    })
  }

  showDialog () {
    this.dialogVisible = true
    this.$nextTick(() => {
      (this.$refs.form as ElForm).resetFields()
    })
  }

  submit () {
    // console.log('数据', this.formData)
    (this.$refs.form as ElForm).validate().then(() => {
      this.$axios.post(this.$apis.assetScheduling.insertAssetInventory, this.formData).then(() => {
        this.dialogVisible = false
        this.loadData()
      })
    })
  }

  dateChange (date: any) {
    this.searchForm.startDate = (date && date[0]) || ''
    this.searchForm.endDate = (date && date[1]) || ''
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onExport (id: string) {
    const loading = this.$loading({
      lock: true,
      text: '导出中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.assetScheduling.exportAssetInventory, { orderId: id }).finally(() => {
      loading.close()
    })
  }

  deleteRow (orderId: string) {
    this.$confirm('确认删除该记录嘛？', '提示').then(() => {
      this.$axios.post(this.$apis.assetScheduling.deleteAssetInventory, {
        orderId
      }).then(() => {
        this.onSearch()
      })
    })
  }

  toPandian (row: any, status: boolean) {
    this.$router.push(`/inventory/Inventory?orderId=${row.orderId}&projectId=${row.projectId}&projectName=${row.projectName}${status ? '&detail=1' : ''}`)
  }
}
